import axios from "axios";
import { links } from "./apiLinks";

export const getEventById = async (id: string) => {
  try {
    const response = await axios.get(`${links.baseUrl}${links.events.get}/${id}`);
    return response;
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

