import { useEffect, useState } from 'react';
import { getEvents } from '../../api/getEvents';
import { EventCard, Loader } from '../../components';
import styles from './style.module.scss';
import figitalLight from '../../assets/image/figitalLight.png';
import InfiniteScroll from 'react-infinite-scroller';

interface IItem {
    id: string;
    title: string;
    description: string;
    date_begin: string;
    date_end: string;
    is_active: boolean;
    image: string;
}

export const MainPage = () => {
    const [events, setEvents] = useState<IItem[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    const [isFirstResponse, setIsFirstResponse] = useState(true)


    useEffect(() => {
        if (!events.length) {
            setIsLoading(false);
        }
    }, [events])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const getNewEvents = async () => {
        if (isFirstResponse) {
            const response = await getEvents();
            setEvents([...events, ...response.data])
            setTotal(response.total);
            setHasMore(response.total > limit)
            setIsFirstResponse(false)
        } else {
            const response = await getEvents(offset + 20, limit + 20);
            setLimit(limit + 20);
            setOffset(offset + 20);
            setEvents([...events, ...response.data])
            setTotal(response.total);
            setHasMore(response.total > limit)
        }
    }

    return (

        <div className={styles.mainBlock}>
            <div className={styles.innerBox}>
                <h1>Анонсы мероприятий</h1>
                <div className={styles.eventsBox}>

                    <InfiniteScroll
                        className={styles.eventsBox}
                        pageStart={0}
                        loadMore={getNewEvents}
                        hasMore={hasMore}
                        loader={<Loader />}
                    >

                        {events?.length ? events.map((item: IItem) => (
                            <div className={styles.eventCardBox} key={item.id}>
                                <EventCard item={item} key={item.id} />
                            </div>
                        )) : null}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
};
