import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

interface IEventCard {
    item: {
        id: string;
        title: string;
        description: string;
        date_begin: any;
        date_end: any;
        is_active: boolean;
        image: string;
    };
}

export const EventCardSmall: React.FC<IEventCard> = ({ item }) => {
    moment.locale('ru');

    const dateStart = moment.utc(item.date_begin).format('D MMMM, dd, HH:mm');
    const dateEnd = moment.utc(item.date_end).format('D MMMM, dd, HH:mm');
    const dateEndHours = moment.utc(item.date_end).format('HH:mm');
    const [isOnlyDay, setIsOnlyDay] = useState(false);

    const areSameDate = () => setIsOnlyDay(moment.utc(item.date_begin).format('D MMMM') === moment.utc(item.date_end).format('D MMMM'));

    useEffect(() => {
        areSameDate()
    }, [])

    return (
        <div className={styles.cardContainer}>
            <img src={item.image} alt='' />
            <div className={styles.infoBox}>
                <p className={styles.date}>
                    {!isOnlyDay ? `${dateStart} - ${dateEnd}` : `${dateStart} - ${dateEndHours}`}
                </p>
                <h4>{item.title}</h4>
                <p className={styles.text}>{item.description}</p>
                <Link to={`/${item.id}`}>Продолжить</Link>
            </div>
        </div>
    );
};
