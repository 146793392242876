import { Link } from 'react-router-dom';
import placeholder from '../../assets/image/placeholder.png';
import styles from './style.module.scss';
import { EventCardSmall, Loader } from '../../components';
import { useEffect, useState } from 'react';
import { getEvents } from '../../api/getEvents';
import InfiniteScroll from 'react-infinite-scroller';
import { useSwipeable } from 'react-swipeable';
import { useNavigate, useParams } from "react-router";
import { getEventById } from '../../api/getEventById';
import moment from 'moment';
import 'moment/locale/ru';

interface IItem {
  id: string;
  title: string;
  description: string;
  date_begin: string;
  date_end: string;
  is_active: boolean;
  image: string;
}
export const EventPage = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [events, setEvents] = useState<IItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isFirstResponse, setIsFirstResponse] = useState(true)
  const [currentEvent, setCurrentEvent] = useState<IItem | null>(null)

  useEffect(() => {
    (async () => {
      const response = await getEventById(id as string);
      setCurrentEvent(response.data)
      window.scrollTo(0, 0);
    })()
  }, [id])

  const getNewEvents = async () => {
    if (isFirstResponse) {
      const response = await getEvents();
      setEvents([...events, ...response.data])
      setTotal(response.total);
      setHasMore(response.total > limit)
      setIsFirstResponse(false)
    } else {
      const response = await getEvents(offset + 20, limit + 20);
      setLimit(limit + 20);
      setOffset(offset + 20);
      setEvents([...events, ...response.data])
      setTotal(response.total);
      setHasMore(response.total > limit)
    }
  }

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        navigate("/");
      }
    },
  });


  moment.locale('ru');
  const dateStart = moment.utc(currentEvent?.date_begin).format('D MMMM, dd, HH:mm');
  const dateEnd = moment.utc(currentEvent?.date_end).format('D MMMM, dd, HH:mm');
  const dateEndHours = moment.utc(currentEvent?.date_end).format('HH:mm');
  const [isOnlyDay, setIsOnlyDay] = useState(false);

  const areSameDate = () => setIsOnlyDay(moment.utc(currentEvent?.date_begin).format('D MMMM') === moment.utc(currentEvent?.date_end).format('D MMMM'));

  useEffect(() => {
    areSameDate()
  }, [])

  return (
    <div className={styles.container} {...handlers}>
      <div className={styles.innerBox}>
        <p className={styles.breadcrumbs}><Link to='/' className={styles.announce}>Анонсы мероприятий</Link> / <span className={styles.name}>{currentEvent?.title}</span></p>

        <img src={currentEvent?.image} alt="" className={styles.image} />

        <p className={styles.date}>
          {!isOnlyDay ? `${dateStart} - ${dateEnd}` : `${dateStart} - ${dateEndHours}`}
        </p>
        <h4 className={styles.title}>{currentEvent?.title}</h4>
        <p className={styles.text}>{currentEvent?.description}</p>


        <h4 className={styles.title}>Другие мероприятия</h4>
        <InfiniteScroll
          className={styles.eventsBox}
          pageStart={0}
          loadMore={getNewEvents}
          hasMore={hasMore}
          loader={<div className={styles.loaderBox}>
            <Loader />
          </div>}
        >

          {events.map((item: IItem) => (
            <EventCardSmall item={item} key={item.id} />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  )
}
